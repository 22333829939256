import Swiper, { Navigation, Pagination, Thumbs } from 'swiper';
Swiper.use([Navigation, Pagination, Thumbs]);

export default function mobileCarousel(
    sliderClass,
    paginationClass = false,
    perView = 1.4,
    spaceBetweenSlides = 16,
    isLoop = true
) {
    if (!sliderClass) return;

    const sliderSections = document.querySelectorAll(`.${sliderClass}`);
    let paginationDivs;

    if (paginationClass) {
        paginationDivs = document.querySelectorAll(`.${paginationClass}`);
    }

    Swiper.use([Navigation]);
    let slidersArray = [];

    const initSwiper = () => {
        sliderSections.forEach((section, i) => {
            let init = false;
            section.classList.add(`${sliderClass}-${i}`);
            if (paginationClass) {
                const paginationEl = paginationDivs[i];
                if (paginationEl)
                    paginationEl.classList.add(`${paginationClass}-${i}`);
            }
            if (!init && window.innerWidth < 991.98) {
                init = true;
                if (section.querySelector('.swiper-wrapper')) {
                    const mobileCarousel = new Swiper(`.${sliderClass}-${i}`, {
                        slidesPerView: perView,
                        spaceBetween: spaceBetweenSlides,
                        slideActiveClass: 'is-active',
                        loop: isLoop,
                        pagination: {
                            el: paginationClass
                                ? `.${paginationClass}-${i}`
                                : false,
                            type: 'bullets',
                            bulletClass: paginationClass
                                ? 'bottom-pagination__bullet'
                                : false,
                            bulletActiveClass: 'active',
                        },
                    });
                    mobileCarousel.on('click', e => {
                        const { clickedIndex } = e;
                        mobileCarousel.slideTo(clickedIndex);
                    });
                    slidersArray.push(mobileCarousel);
                }
            } else if (window.innerWidth > 991.98) {
                slidersArray.forEach(item => {
                    item.destroy();
                });
                init = false;
            }
        });
    };

    initSwiper();
    window.addEventListener('resize', initSwiper);
}
