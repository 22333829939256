import Swiper, { Navigation, Pagination } from 'swiper';
Swiper.use([Navigation, Pagination]);

import mobileCarousel from '../utils/mobileCarousel';

const desktopCarousels = document.querySelectorAll('.news__carousel');
const mobileCarousels = document.querySelectorAll('.news__carousel-mobile');

if (mobileCarousels && mobileCarousels.length > 0) {
    mobileCarousel(
        'news__carousel-mobile',
        'swiper-pagination-news',
        'auto',
        0
    );
} else if (desktopCarousels && desktopCarousels.length > 0) {
    Swiper.use([Navigation, Pagination]);
    let slidersArray = [];
    desktopCarousels.forEach((section, i) => {
        let init = false;
        if (!init) {
            init = true;
            if (section.querySelector('.swiper-wrapper')) {
                const scrollToItem = () => {
                    const queryString = window.location.search;
                    const urlParams = new URLSearchParams(queryString);
                    const postId = urlParams.get('post_id');

                    if (postId) {
                        setTimeout(() => {
                            //section.scrollIntoView({ behavior: 'smooth' });
                        }, 500);
                    }
                };

                scrollToItem();

                const carousel = new Swiper(section, {
                    slideActiveClass: 'is-active',
                    breakpoints: {
                        0: {
                            slidesPerView: 1.25,
                            spaceBetween: 0,
                        },
                        992: {
                            slidesPerView: 3,
                            spaceBetween: 0,
                        },
                    },
                    pagination: {
                        el: section.parentElement.querySelector(
                            '.swiper-pagination-news'
                        ),
                        type: 'bullets',
                        bulletClass: 'bottom-pagination__bullet',
                        bulletActiveClass: 'active',
                    },
                    navigation: {
                        nextEl: section.parentElement.querySelector(
                            '.slider-arrow--next'
                        ),
                        prevEl: section.parentElement.querySelector(
                            '.slider-arrow--prev'
                        ),
                    },
                });
                slidersArray.push(carousel);
            }
        }
    });
}
